import classNames from 'classnames';
import Link from 'next/link';
import type { FC, PropsWithChildren } from 'react';

import Button from '../Button';
import Icon from '../Icon';
import Block from '../ResumeChecker/Block';
import Text from '../Text';

type TargetAudiencesProps = {
  type: 'jobSeekers' | 'recruiters';
  className?: string;
};
const TargetAudiences: FC<PropsWithChildren<TargetAudiencesProps>> = ({
  children,
  type,
  className,
}) => {
  const isRecruiters = type === 'recruiters';
  return (
    <Block className="flex flex-1 flex-col justify-between !px-4">
      <div className="flex flex-col gap-6">
        <Icon
          icon={
            isRecruiters
              ? 'material-symbols-light:business-center-outline'
              : 'material-symbols-light:clinical-notes-outline'
          }
          variant={isRecruiters ? 'candidatePrimary' : 'candidateSecondary'}
          fontSize={54}
          className="p-2"
        />
        <div>
          <Text className="font-semibold" variant="h4">
            {isRecruiters ? 'For Recruiters' : 'For Candidates'}
          </Text>
          <div className={classNames('pt-2', className)}>{children}</div>
        </div>
      </div>
      <div className="flex flex-col gap-4 pt-8 md:flex-row">
        <Link href={isRecruiters ? '/recruiter' : '/resume-checker'}>
          <Button
            tabIndex={-1}
            className={classNames('flex-1')}
            variant={isRecruiters ? 'jbPrimary' : 'jbOrange'}
          >
            {isRecruiters
              ? 'Try tools for Recruiters'
              : 'Try tools for Candidates'}
          </Button>
        </Link>
        {isRecruiters && (
          <Link href="/dashboard/pricing">
            <Button
              tabIndex={-1}
              className="flex-1"
              variant="candidate-secondary"
            >
              See the Pricing
            </Button>
          </Link>
        )}
      </div>
    </Block>
  );
};

export default TargetAudiences;
