import Autoplay from 'embla-carousel-autoplay';

import ImgForJobSeekers from '@/assets/landing/for-job-seekers.webp';
import ImgForRecruiters from '@/assets/landing/for-recruiters.webp';
import ImgResumeChecker from '@/assets/landing/resume-checker.webp';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';

import CarouselItemTemplate from './CarouselItemTemplate';

const HeroCarousel = () => {
  const slides = [
    {
      key: 'resumeBuilder',
      render: (
        <CarouselItemTemplate
          title="AI Resume Builder and Resume Checker"
          description="Job search can be exhausting. Explore free innovative tools for Job Seekers and get that job interview."
          buttonText="Explore tools now"
          buttonHref="/resume-checker"
          buttonVariant="jbPrimary"
          targetAudiencesText="For Job Seekers"
          img={ImgForJobSeekers}
        />
      ),
    },
    {
      key: 'forRecruiters',
      render: (
        <CarouselItemTemplate
          title="Smart HR Tools: Hire top talents"
          description="Explore a wide range of AI tools for recruiters. AI assistant to organize, and connect with thousands of candidates.Try it Today."
          buttonText="Explore tools now"
          buttonHref="/recruiter"
          buttonVariant="jbSecondary"
          targetAudiencesText="For Recruiters"
          img={ImgForRecruiters}
          className="bg-gradient-to-r from-neutral-950 to-secondary-900 text-jb-neutral-100"
        />
      ),
    },
    {
      key: 'resumeChecker',
      render: (
        <CarouselItemTemplate
          title="AI Supported Resume Checker for Job Seekers"
          description="MindPal’s Free AI Resume Checker helps you to fix mistakes, optimize keywords and prepare an ATS friendly resume. All of that to finally get that interview."
          buttonText="Explore tool now"
          buttonHref="/resume-checker"
          buttonVariant="jbSecondary"
          img={ImgResumeChecker}
          className="bg-jb-primary-500 text-jb-neutral-100"
        />
      ),
    },
  ];
  return (
    <Carousel
      opts={{ loop: true }}
      showIndicators
      plugins={[Autoplay({ delay: 5000 })]}
      className="w-full max-w-[90vw] rounded-xl shadow-big-moduls"
    >
      <CarouselContent className="flex-1 text-jb-neutral-1000">
        {slides.map(({ key, render }) => (
          <CarouselItem key={key}>{render}</CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};

export default HeroCarousel;
