import { motion } from 'framer-motion';

import { getStepperStatus } from '@/utils/getStepperStatus';

type Props<T> = {
  steps: T[];
  currentIndex: number;
};

const ProgressStepTracker = <T extends unknown>({
  steps,
  currentIndex,
}: Props<T>) => {
  const getStepBackgroundColor = (step: string) => {
    if (step === 'inprogress')
      return {
        backgroundColor: '#603DB5',
        width: '1.75rem',
        marginLeft: '0.25rem',
        marginRight: '0.25rem',
      };
    return { backgroundColor: '#BFC6CC', width: '0.5rem' };
  };

  return (
    <motion.div className="flex flex-row gap-1">
      {steps.map((step, index) => (
        <motion.div
          key={JSON.stringify(`step${index}`)}
          layout
          animate={getStepBackgroundColor(
            getStepperStatus({ index, currentIndex })
          )}
          className="h-2 rounded-lg"
        />
      ))}
    </motion.div>
  );
};

export default ProgressStepTracker;
