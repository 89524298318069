import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

const Stack: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center gap-12 md:px-28 px-6 md:pt-40 pt-28 overflow-hidden',
        className
      )}
    >
      <div className="flex flex-col items-center justify-center gap-12 text-jb-neutral-1000">
        {children}
      </div>
    </div>
  );
};

export default Stack;
