import classNames from 'classnames';
import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import Link from 'next/link';
import type { ComponentProps, FC } from 'react';

import Button from '@/components/Button';
import Block from '@/components/ResumeChecker/Block';
import Text from '@/components/Text';

type Props = {
  targetAudiencesText?: string;
  title: string;
  description: string;
  buttonText: string;
  buttonHref: string;
  buttonVariant: ComponentProps<typeof Button>['variant'];
  img: StaticImageData;
  className?: string;
};
const CarouselItemTemplate: FC<Props> = ({
  buttonHref,
  buttonText,
  buttonVariant,
  description,
  img,
  targetAudiencesText,
  title,
  className,
}) => {
  return (
    <Block
      className={classNames(
        'flex md:flex-row flex-col gap-10 !py-8 !pb-20 lg:!px-12 lg:!py-20 h-full',
        className
      )}
    >
      <div className="flex flex-4 flex-col justify-center gap-10">
        {targetAudiencesText && (
          <Text variant="h3" className="text-h5 md:text-h3">
            {targetAudiencesText}
          </Text>
        )}
        <div className="flex flex-col gap-6">
          <Text variant="h2" className="text-h4 font-semibold md:text-h2">
            {title}
          </Text>
          <Text variant="h4" className="!text-body md:text-h4">
            {description}
          </Text>
        </div>
        <Link href={buttonHref}>
          <Button
            tabIndex={-1}
            variant={buttonVariant}
            className="w-fit"
            suffixIcon="material-symbols-light:arrow-forward"
          >
            {buttonText}
          </Button>
        </Link>
      </div>
      <div className="flex flex-5 items-center justify-center">
        <Image src={img} alt="" />
      </div>
    </Block>
  );
};

export default CarouselItemTemplate;
