import Image from 'next/image';

import AP from '@/assets/in-media/aplogo-logo.webp';
import StemReport from '@/assets/in-media/california.webp';
import CaliforniaBusiness from '@/assets/in-media/california-business-logo.webp';
import GlobalTechTimes from '@/assets/in-media/global.webp';
import Ktla from '@/assets/in-media/ktla.webp';
import hrTechSeries from '@/assets/in-media/techhr.webp';

import Block from '../ResumeChecker/Block';

const InMedia = () => {
  const media = [
    {
      key: 'CaliforniaBusiness',
      img: CaliforniaBusiness,
      href: 'https://www.californiabusinessdigest.com/article/715203611-the-beta-version-of-mindpal-s-ai-job-matching-is-now-available',
    },
    {
      key: 'StemReport',
      img: StemReport,
      href: 'https://www.californiastemreporter.com/article/715203611-the-beta-version-of-mindpal-s-ai-job-matching-is-now-available',
    },
    {
      key: 'GlobalTechTimes',
      img: GlobalTechTimes,
      href: 'https://www.globaltechtimes.com/article/715203611-the-beta-version-of-mindpal-s-ai-job-matching-is-now-available',
    },
    {
      key: 'AP',
      img: AP,
      href: 'https://apnews.com/press-release/ein-presswire-newsmatics/san-francisco-dc2a433e27314bdbdb47d9358f57784f',
    },
    {
      key: 'Ktla',
      img: Ktla,
      href: 'https://ktla.com/business/press-releases/ein-presswire/715203611/the-beta-version-of-mindpals-ai-job-matching-is-now-available/',
    },
    {
      key: 'hrTechSeries',
      img: hrTechSeries,
      href: 'https://techrseries.com/recruitment-and-on-boarding/the-beta-version-of-mindpals-ai-job-matching-is-now-available/',
    },
  ];
  return (
    <Block className="grid w-full grid-cols-2 gap-20 shadow-big-moduls md:grid-cols-6">
      {media.map(({ img, key, href }) => (
        <a
          href={href}
          key={key}
          className="flex flex-1 items-center justify-center object-cover"
        >
          <Image src={img} alt="" className="max-h-14 w-auto" />
        </a>
      ))}
    </Block>
  );
};

export default InMedia;
