import Link from 'next/link';
import type { FC, PropsWithChildren } from 'react';

import Button from '../Button';
import Text from '../Text';

type FeatureInfoProps = {
  href: string;
  title: string;
};
const FeatureInfo: FC<PropsWithChildren<FeatureInfoProps>> = ({
  href,
  title,
  children,
}) => {
  return (
    <div className="flex flex-col justify-center gap-4">
      <Text variant="h2">{title}</Text>
      <div className="pt-6">{children}</div>
      <Link className="mt-8" href={href}>
        <Button
          tabIndex={-1}
          variant="jbPrimary"
          suffixIcon="material-symbols-light:arrow-forward"
        >
          Click here to learn more
        </Button>
      </Link>
    </div>
  );
};

export default FeatureInfo;
