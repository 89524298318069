import classNames from 'classnames';
import type { ComponentProps, ForwardedRef, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

type Props = ComponentProps<'div'>;

const Block = (
  { children, className, ...props }: PropsWithChildren<Props>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  return (
    <div
      {...props}
      ref={ref}
      className={classNames(
        'rounded-2xl bg-jb-neutral-100 lg:px-8 lg:py-8 px-3 py-6',
        className
      )}
    >
      {children}
    </div>
  );
};

Block.displayName = 'Block';
export default forwardRef(Block);
