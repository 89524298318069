import Block from '../ResumeChecker/Block';
import Text from '../Text';
import TargetAudiences from './TargetAudiences';

const OfferForTwoAudiences = () => {
  return (
    <Block className="flex w-full flex-col items-center justify-center gap-10 bg-jb-primary-500 md:gap-20 lg:!px-16 lg:!py-20">
      <div className="flex flex-col items-center justify-center gap-4">
        <Text variant="h2" className="text-center text-jb-neutral-100">
          “From people to people with the assistance of AI”
        </Text>
        <Text
          variant="h5"
          className="max-w-2xl text-center text-jb-neutral-100"
        >
          AI would not exist without the human and with that in mind, we create
          our tools. Check our newest creations.
        </Text>
      </div>
      <div className="flex w-full flex-col gap-8 md:flex-row">
        <TargetAudiences type="jobSeekers" className="flex flex-col gap-4">
          <Text variant="jb-body-medium">
            One of our flag tools is user friendly Resume Analyzer that educates
            and shows resume mistakes and how to fix them.
          </Text>
          <Text variant="jb-body-medium">
            Our go to is a platform that connects people at a similar stage in
            life so they can help each other and share their experiences. Check
            it now when it`s still fo free, just create an account and start
            your new job.
          </Text>
        </TargetAudiences>
        <TargetAudiences type="recruiters" className="flex flex-col gap-4">
          <Text variant="jb-body-medium">
            We offer an{' '}
            <strong>AI-powered platform for recruiters and HR firms</strong>
            looking to save time, money and improve results.
          </Text>
          <Text variant="jb-body-medium">
            We listen to the needs of our customers and have decided to expand
            our offer to create{' '}
            <strong>
              tools tailored to the specific needs of companies, just as they
              wish
            </strong>
            , like AI HR boots.
          </Text>
        </TargetAudiences>
      </div>
    </Block>
  );
};

export default OfferForTwoAudiences;
