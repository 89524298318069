import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import type { FC, ReactElement } from 'react';

import AiHrBot from '@/assets/landing/ai-hr-bots.webp';
import BulletPoints from '@/assets/landing/bullet-points.webp';
import JobBoard from '@/assets/landing/job-board.webp';
import JobMatching from '@/assets/landing/job-matching.webp';
import Michelle from '@/assets/landing/michelle-ai-hr-assistant.webp';
import Ats from '@/assets/landing/mindpal-ats.webp';
import RegisterToUnlock from '@/assets/landing/register-to-unlock-full-report.webp';
import ResumeGenerator from '@/assets/landing/resume-blind-generator.webp';
import ResumeQuickAnalysis from '@/assets/landing/resume-quick-analysis.webp';
import SeeResult from '@/assets/landing/see-the-results.webp';
import UploadFile from '@/assets/landing/upload-file.webp';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import FeatureInfo from '@/components/Landing/FeatureInfoProps';
import HeroCarousel from '@/components/Landing/HeroCarousel/HeroCarousel';
import ImageTextSection from '@/components/Landing/ImageTextSection';
import InMedia from '@/components/Landing/InMedia';
import Layout from '@/components/Landing/Layout';
import OfferForTwoAudiences from '@/components/Landing/OfferForTwoAudiences ';
import Stack from '@/components/Landing/Stack';
import Block from '@/components/ResumeChecker/Block';
import Text from '@/components/Text';
import { ToggleGroup, ToggleItem } from '@/components/ui/toggle-group';
import { smoothScroll } from '@/utils/smoothScroll';

const Landing = () => {
  const aIResumeChecker = [
    {
      title: 'Upload Resume file',
      description:
        'Simply pick the document you would like to analyze at the moment.',
      img: UploadFile,
    },
    {
      title: 'See the Results',
      description:
        'Wait few second, AI will create a report for you with detailed informations.',
      img: SeeResult,
    },
    {
      title: 'Register to unlock full report',
      description:
        'Create an account within seconds to unlock the full report for free.',
      img: RegisterToUnlock,
    },
  ];

  const platformAdvantagesForJobSeekers = [
    {
      icon: 'stash:stars',
      title: 'Job Tracker',
      descriptionParagraphs: [
        'Track your progress and keep all your applications in one place. You can also add notes for each position.',
        'Sync with your calendar to create events, set reminders to apply for job offers, and keep track of upcoming interviews.',
      ],
    },
    {
      icon: 'material-symbols:description',
      title: 'AI Resume Builder',
      descriptionParagraphs: [
        'Create a resume from scratch with the assistance of AI. Already have a resume? Analyze it with our resume checker and enhance it to your liking.',
        'Already have your eye on a job offer? Customize your resume to match the job description with just one click.',
      ],
    },
    {
      icon: 'material-symbols:group-outline',
      title: 'Pals Community',
      descriptionParagraphs: [
        'Motivation is key to success in a job hunt. Connect with people in a similar situation to motivate each other.',
        "Learn from others' experiences and build a social network — maybe someone will recommend you.",
      ],
    },
  ];

  const platformAdvantagesForRecruiters = [
    {
      icon: 'material-symbols:search',
      title: 'High Search Precision',
      descriptionParagraphs: [
        'MindPal combines keyword, boolean, and semantic search.',
        'Identify the best candidates with pinpoint accuracy.',
        'Provides actionable suggestions to guide recruiters every step of the way.',
      ],
    },
    {
      icon: 'stash:stars',
      title: 'All-in-One Solution',
      descriptionParagraphs: [
        'A powerful candidate search engine to match top talent with dream jobs.',
        'A Resume Generator with Anonymizer to streamline document creation.',
        'Job Matching tools, customizable templates, and more.',
      ],
    },
    {
      icon: 'material-symbols:trending-up',
      title: 'Save Time. Build Connections',
      descriptionParagraphs: [
        'Automate repetitive tasks like screening and sourcing candidates.',
        'Use seamless LinkedIn integrations and Slack plugins to enhance workflows.',
        'Focus your energy on interviews and building strong relationships with top talent.',
      ],
    },
  ];

  return (
    <>
      <style jsx global>
        {`
          body {
            background: #fafafa;
          }
        `}
      </style>
      <Stack>
        <HeroCarousel />
      </Stack>
      <Stack>
        <Block className="flex w-full flex-col items-center justify-center gap-20 shadow-big-moduls lg:!px-16 lg:!py-20">
          <div className="flex flex-col items-center justify-center">
            <Text variant="h3" className="font-semibold">
              Try our newest tool
            </Text>
            <Text variant="h1" className="text-jb-primary-500">
              AI Resume Checker
            </Text>
            <Text variant="h5" className="max-w-2xl pt-6 text-center">
              Create an ATS-friendly resume and get hired faster. MindPal’s Free
              AI Resume Analyzer helps you fix mistakes, optimize keywords.
            </Text>
            <Link href="/resume-checker">
              <Button
                tabIndex={-1}
                className="mt-10"
                suffixIcon="material-symbols-light:arrow-forward"
                variant="jbPrimary"
              >
                Try Resume Checker{' '}
              </Button>
            </Link>
          </div>
          <div className="flex flex-col gap-8 md:flex-row">
            {aIResumeChecker.map(({ title, description, img }) => (
              <div className="flex flex-1 flex-col gap-8" key={title}>
                <Image src={img} alt="" />
                <div>
                  <Text variant="jb-body-large" className="font-semibold">
                    {title}
                  </Text>
                  <Text variant="jb-body-medium">{description}</Text>
                </div>
              </div>
            ))}
          </div>
        </Block>
        <InMedia />
        <OfferForTwoAudiences />
        <div className="w-full">
          <ToggleGroup
            value="For Candidates"
            type="single"
            variant="unstyled"
            onValueChange={(newValue) => {
              if (newValue === 'For Recruiters')
                smoothScroll('#for-recruiters');
            }}
            className="flex w-fit flex-row gap-6 rounded-2xl bg-jb-neutral-100 p-4 shadow-big-moduls [&>button]:rounded-lg [&>button]:px-4 [&>button]:py-1 data-[state=on]:[&>button]:bg-jb-neutral-300 data-[state=on]:[&>button]:font-semibold"
          >
            <ToggleItem value="For Candidates">For Candidates</ToggleItem>
            <ToggleItem value="For Recruiters">For Recruiters</ToggleItem>
          </ToggleGroup>
        </div>
        <div className="flex w-full flex-col items-center justify-center py-20">
          <Text variant="h1" className="text-center">
            For Job Seekers
          </Text>
          <Text className="max-w-3xl pt-10 text-center" variant="h5">
            “According to a range of surveys, and even studies using eye
            tracking devices,{' '}
            <strong>
              recruiters spend an average of just 7 to 9 seconds reviewing a
              resume
            </strong>
            ”
          </Text>
          <Link href="/resume-checker">
            <Button tabIndex={-1} variant="jbPrimary" className="mt-8">
              Go to MindPal Candidate
            </Button>
          </Link>
        </div>
        <ImageTextSection
          imageBlockClassName="lb:!p-0 !p-0"
          img={ResumeQuickAnalysis}
          reverse
        >
          <FeatureInfo href="/resume-checker" title="AI Resume Checker">
            <Text variant="h5">
              Analyze your resume to create an ATS-friendly one and get hired
              faster. MindPal’s Free AI Resume Analyzer helps you fix mistakes,
              optimize keywords, and build a job-winning resume in minutes.
            </Text>
          </FeatureInfo>
        </ImageTextSection>
        <ImageTextSection imageBlockClassName="lb:!p-0 !p-0" img={BulletPoints}>
          <FeatureInfo
            href="/bullet-points"
            title="Bullet Points Resume Generator"
          >
            <Text variant="h5">
              <strong>Create a powerful bullet points</strong> focused on
              impact, results and value that you bring to the work environment.
              Tools is based on the Powerful Bullet Formula.
            </Text>
          </FeatureInfo>
        </ImageTextSection>
        {/* Coming soon – once the blog for candidates is ready */}
        {/* <ImageTextSection
          img={CareerGuides}
          imageBlockClassName="lb:!p-0 !p-0"
          reverse
        >
          <FeatureInfo href="/" title="Career Guides">
            <Text variant="h5">
              Newest tips and tricks from the Tech industry for job seekers.
              Learn how to create a good resume and prepare for the stressful
              interview.
            </Text>
          </FeatureInfo>
        </ImageTextSection> */}
        <ImageTextSection
          img={JobBoard}
          imageBlockClassName="lb:!p-0 !p-0"
          reverse
        >
          <FeatureInfo href="/jobs" title="Job Board">
            <div className="flex flex-col gap-6">
              <Text variant="h5">
                Clear communicates shows when is the best time to apply or that
                job offers will expire soon. Don`t miss your chance.
              </Text>
              <Text variant="h5">
                AI analyzes and evaluates the candidate based on data from their
                CV to then match them to the job offer.
              </Text>
            </div>
          </FeatureInfo>
        </ImageTextSection>
        <div className="flex w-full flex-col items-center justify-center gap-20 py-28">
          <div className="flex flex-col items-center justify-center gap-8">
            <Text variant="h2" className="text-center">
              All in one platform for job seekers
            </Text>
            <Text variant="h5" className="max-w-lg text-center">
              You are not alone, there is more people in situation like your. We
              know the drill and want to help you
            </Text>
          </div>
          <div className="flex w-full flex-col items-stretch justify-center gap-6 md:flex-row">
            {platformAdvantagesForJobSeekers.map(
              ({ descriptionParagraphs, icon, title }, index) => (
                <PlatformAdvantagesItem
                  key={title}
                  isFeatured={index % 2 !== 0}
                  descriptionParagraphs={descriptionParagraphs}
                  icon={icon}
                  title={title}
                  isCommingSoon
                />
              )
            )}
          </div>
        </div>
      </Stack>
      <Stack className="bg-dark-gradient text-jb-neutral-100">
        <div className="flex flex-col items-center justify-center gap-10 py-20 text-jb-neutral-100">
          <Text variant="h1" id="for-recruiters" className="text-center">
            For Recruiters
          </Text>
          <Text className="max-w-2xl text-center">
            AI to organize, and connect with thousands of candidates straight
            from your ATS. Try our AI tools to automate recruiters daily tasks.
          </Text>
          <Link href="/recruiter">
            <Button variant="jbPrimary" tabIndex={-1}>
              Go to MindPal Recruiter{' '}
            </Button>
          </Link>
        </div>
        <ImageTextSection
          theme="dark"
          reverse
          img={ResumeGenerator}
          imageBlockClassName="lb:!p-0 !p-0"
        >
          <FeatureInfo href="/resume" title="Resume Generator">
            <div className="flex flex-col gap-6">
              <Text variant="h5">
                Anonymize, edit, generate thousands of Resumes faster with the
                power of Artificial Intelligence.
              </Text>
              <Text variant="h5">
                Try Resume Generator to automate everyday tasks. Highlight
                candidates strengths, anonymize personal data and include your
                logotype on the templates.
              </Text>
            </div>
          </FeatureInfo>
        </ImageTextSection>
        <ImageTextSection
          theme="dark"
          img={JobMatching}
          imageBlockClassName="lb:!p-0 !p-0"
        >
          <FeatureInfo href="/job-matching" title="Job Matching">
            <div className="flex flex-col gap-6">
              <Text variant="h5">
                Anonymize, edit, generate thousands of Resumes faster with the
                power of Artificial Intelligence.
              </Text>
              <Text variant="h5">
                Try Resume Generator to automate everyday tasks. Highlight
                candidates strengths, anonymize personal data and include your
                logotype on the templates.
              </Text>
            </div>
          </FeatureInfo>
        </ImageTextSection>
        <ImageTextSection
          theme="dark"
          reverse
          img={Ats}
          imageBlockClassName="lb:!p-0 !p-0"
        >
          <FeatureInfo href="/ats" title="MindPal ATS">
            <div className="flex flex-col gap-6">
              <Text variant="h5">
                Anonymize, edit, generate thousands of Resumes faster with the
                power of Artificial Intelligence.
              </Text>
              <Text variant="h5">
                Try Resume Generator to automate everyday tasks. Highlight
                candidates strengths, anonymize personal data and include your
                logotype on the templates.
              </Text>
            </div>
          </FeatureInfo>
        </ImageTextSection>
        <ImageTextSection
          theme="dark"
          img={AiHrBot}
          imageBlockClassName="lb:!p-0 !p-0"
        >
          <FeatureInfo href="/hr-bots" title="AI HR Bots">
            <div className="flex flex-col gap-6">
              <Text variant="h5">
                Every tool is made for a client’s special request. First, we
                understand the client needs, then we create the best solution.
              </Text>
              <Text variant="h5">
                Our bots go through job boards, databases, and social media to
                match candidates’ qualifications with your requirements,
                ensuring you never miss out any talents.
              </Text>
            </div>
          </FeatureInfo>
        </ImageTextSection>
        <ImageTextSection
          theme="dark"
          reverse
          img={Michelle}
          imageBlockClassName="lb:!p-0 !p-0"
        >
          <FeatureInfo href="/recruiter" title="Michelle AI HR Assistant ">
            <div className="flex flex-col gap-6">
              <Text variant="h5">
                Anonymize, edit, generate thousands of Resumes faster with the
                power of Artificial Intelligence.
              </Text>
              <Text variant="h5">
                Try Resume Generator to automate everyday tasks. Highlight
                candidates strengths, anonymize personal data and include your
                logotype on the templates.
              </Text>
            </div>
          </FeatureInfo>
        </ImageTextSection>
        <div className="flex w-full flex-col items-center justify-center gap-20 py-28 pb-52">
          <div className="flex flex-col items-center justify-center gap-8 text-jb-neutral-100">
            <Text variant="h2" className="text-center">
              All in one solution for Recruiters
            </Text>
            <Text variant="h5" className="max-w-lg text-center">
              AI to organize, and connect with thousands of candidates straight
              from your ATS. Try our AI tools to automate recruiters daily tasks
              and save time.
            </Text>
          </div>
          <div className="flex w-full flex-col items-stretch justify-center gap-6 md:flex-row">
            {platformAdvantagesForRecruiters.map(
              ({ descriptionParagraphs, icon, title }, index) => (
                <PlatformAdvantagesItem
                  key={title}
                  isFeatured={index % 2 !== 0}
                  descriptionParagraphs={descriptionParagraphs}
                  icon={icon}
                  title={title}
                  theme="dark"
                  type="list"
                />
              )
            )}
          </div>
        </div>
      </Stack>
    </>
  );
};
export default Landing;
Landing.getLayout = (page: ReactElement) => (
  <Layout theme="light">{page}</Layout>
);

type PlatformAdvantagesItemProps = {
  icon: string;
  title: string;
  descriptionParagraphs: string[];
  isFeatured?: boolean;
  theme?: 'light' | 'dark';
  isCommingSoon?: boolean;
  type?: 'list' | 'paragraph';
};
const PlatformAdvantagesItem: FC<PlatformAdvantagesItemProps> = ({
  descriptionParagraphs,
  icon,
  title,
  isFeatured,
  theme = 'light',
  isCommingSoon,
  type = 'paragraph',
}) => {
  return (
    <div
      key={title}
      className={classNames('flex flex-5', {
        'py-10 !flex-4': !isFeatured,
      })}
    >
      <Block
        className={classNames('relative flex-1 overflow-hidden', {
          'bg-primary-100/5 text-neutral-0': theme === 'dark',
          'shadow-big-moduls': theme === 'light',
        })}
      >
        {isCommingSoon && (
          <div className="absolute -right-20 top-10 flex rotate-45 items-center bg-jb-primary-300 px-20 text-jb-primary-500">
            <Text
              variant="jb-body-medium"
              className="w-full text-center font-semibold"
            >
              Coming soon
            </Text>
          </div>
        )}
        <div className="flex flex-col gap-6">
          <Icon
            icon={icon}
            variant={theme === 'dark' ? 'dark' : 'candidatePrimary'}
            className="p-2"
            fontSize={40}
          />
          <Text variant="h4" className="font-semibold">
            {title}
          </Text>
          {type === 'paragraph' && (
            <div className="flex flex-col gap-4">
              {descriptionParagraphs.map((paragraph) => (
                <Text
                  key={paragraph}
                  variant={isFeatured ? 'body' : 'jb-body-small'}
                >
                  {paragraph}
                </Text>
              ))}
            </div>
          )}
          {type === 'list' && (
            <ul className="flex flex-col gap-4">
              {descriptionParagraphs.map((paragraph) => (
                <li key={paragraph} className="flex flex-row gap-4">
                  <div>
                    <Icon
                      icon="material-symbols-light:check-circle-outline"
                      fontSize={24}
                    />
                  </div>
                  <Text
                    key={paragraph}
                    variant={isFeatured ? 'body' : 'jb-body-small'}
                  >
                    {paragraph}
                  </Text>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Block>
    </div>
  );
};
