import classNames from 'classnames';
import { motion } from 'framer-motion';
import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import type { FC, PropsWithChildren } from 'react';

import Block from '../ResumeChecker/Block';

const MotionBlock = motion(Block);

const slideInRight = {
  initial: { x: -100, opacity: 0 },
  whileInView: { x: 0, opacity: 1 },
  transition: { delay: 0.4, duration: 1 },
  viewport: { once: true, amount: 0.3 },
};

const slideInLeft = {
  initial: { x: 100, opacity: 0 },
  whileInView: { x: 0, opacity: 1 },
  transition: { delay: 0.8, duration: 1 },
  viewport: { once: true, amount: 0.3 },
};

const slideInTop = {
  initial: { y: 100, opacity: 0 },
  whileInView: { y: 0, opacity: 1 },
  transition: { delay: 0.4, duration: 1 },
  viewport: { once: true, amount: 0.3 },
};

type Props = {
  img: StaticImageData;
  reverse?: boolean;
  imageBlockClassName?: string;
  childrenBlockClassName?: string;
  theme?: 'light' | 'dark';
};

const ImageTextSection: FC<PropsWithChildren<Props>> = ({
  img,
  children,
  reverse,
  imageBlockClassName,
  childrenBlockClassName,
  theme = 'light',
}) => {
  if (reverse)
    return (
      <div className="flex flex-col items-stretch gap-8 text-jb-neutral-1000 lg:flex-row">
        <div className="flex flex-1 items-stretch">
          <MotionBlock
            {...slideInTop}
            className={classNames('flex-1', childrenBlockClassName, {
              'bg-primary-100/5 text-neutral-0': theme === 'dark',
              'shadow-big-moduls': theme === 'light',
            })}
          >
            {children}
          </MotionBlock>
        </div>
        <div className="flex flex-1 items-stretch">
          <MotionBlock
            {...slideInLeft}
            className={classNames(
              'flex-1 flex items-center justify-center overflow-hidden',
              imageBlockClassName,
              {
                'bg-primary-100/5 text-neutral-0': theme === 'dark',
                'shadow-big-moduls': theme === 'light',
              }
            )}
          >
            <Image src={img} alt="" />
          </MotionBlock>
        </div>
      </div>
    );
  return (
    <div className="flex flex-col-reverse items-stretch gap-8 text-jb-neutral-1000 lg:flex-row">
      <div className="flex flex-1 items-stretch">
        <MotionBlock
          {...slideInRight}
          className={classNames(
            'flex-1 flex items-center justify-center overflow-hidden',
            imageBlockClassName,
            {
              'bg-primary-100/5 text-neutral-0': theme === 'dark',
              'shadow-big-moduls': theme === 'light',
            }
          )}
        >
          <Image src={img} alt="" />
        </MotionBlock>
      </div>
      <div className="flex flex-1 items-stretch">
        <MotionBlock
          {...slideInTop}
          className={classNames('flex-1', childrenBlockClassName, {
            'bg-primary-100/5 text-neutral-0': theme === 'dark',
            'shadow-big-moduls': theme === 'light',
          })}
        >
          {children}
        </MotionBlock>
      </div>
    </div>
  );
};

export default ImageTextSection;
